import blocksFragments from "./fragments";
const API_URL =
  process.env.WORDPRESS_API_URL ||
  process.env.NEXT_PUBLIC_VERCEL_ENV === "preview"
    ? "https://scap-wp.azurewebsites.net/graphql/"
    : "https://scap-wp.azurewebsites.net/graphql/";

async function fetchAPI(query, { variables } = {}) {
  const headers = { "Content-Type": "application/json" };

  if (process.env.WORDPRESS_AUTH_REFRESH_TOKEN) {
    headers[
      "Authorization"
    ] = `Bearer ${process.env.WORDPRESS_AUTH_REFRESH_TOKEN}`;
  }

  const res = await fetch(API_URL, {
    method: "POST",
    headers,
    body: JSON.stringify({
      query,
      variables,
    }),
  });

  const json = await res.json();
  if (json.errors) {
    console.error(json.errors);
    throw new Error("Failed to fetch API");
  }
  return json.data;
}

export async function getPreviewPost(id, idType = "DATABASE_ID") {
  const data = await fetchAPI(
    `
    query PreviewPost($id: ID!, $idType: PostIdType!) {
      post(id: $id, idType: $idType) {
        databaseId
        slug
        status
      }
    }`,
    {
      variables: { id, idType },
    }
  );
  return data.post;
}

export async function getAllPostsWithSlug() {
  const data = await fetchAPI(`
    {
      posts(first: 10000) {
        edges {
          node {
            slug
          }
        }
      }
    }
  `);
  return data?.posts;
}

export async function getAllPostsForHome(preview) {
  const data = await fetchAPI(
    `
    query AllPosts {
      posts(first: 20, where: { orderby: { field: DATE, order: DESC } }) {
        edges {
          node {
            title
            excerpt
            slug
            date
            featuredImage {
              node {
                sourceUrl
                altText
                title
              }
            }
            categories(last: 1) {
              nodes {
                name
                id
              }
            }
            author {
              node {
                name
                firstName
                lastName
                avatar {
                  url
                }
              }
            }
          }
        }
      }
    }
  `,
    {
      variables: {
        onlyEnabled: !preview,
        preview,
      },
    }
  );

  return data?.posts;
}

export async function getPostAndMorePosts(slug, preview, previewData) {
  const postPreview = preview && previewData?.post;
  // The slug may be the id of an unpublished post
  const isId = Number.isInteger(Number(slug));
  const isSamePost = isId
    ? Number(slug) === postPreview.id
    : slug === postPreview.slug;
  const isDraft = isSamePost && postPreview?.status === "draft";
  const isRevision = isSamePost && postPreview?.status === "publish";
  const data = await fetchAPI(
    `
    fragment AuthorFields on User {
      name
      firstName
      lastName
      avatar {
        url
      }
    }
    fragment PostFields on Post {
      title
      excerpt
      slug
      date
      featuredImage {
        node {
          sourceUrl
          altText
          title
        }
      }
      author {
        node {
          ...AuthorFields
        }
      }
      categories {
        edges {
          node {
            name
          }
        }
      }
      tags {
        edges {
          node {
            name
          }
        }
      }
    }
    query PostBySlug($id: ID!, $idType: PostIdType!) {
      post(id: $id, idType: $idType) {
        ...PostFields
        content
        ${
          // Only some of the fields of a revision are considered as there are some inconsistencies
          isRevision
            ? `
        revisions(first: 1, where: { orderby: { field: MODIFIED, order: DESC } }) {
          edges {
            node {
              title
              excerpt
              content
              author {
                node {
                  ...AuthorFields
                }
              }
            }
          }
        }
        `
            : ""
        }
      }
      posts(first: 3, where: { orderby: { field: DATE, order: DESC } }) {
        edges {
          node {
            ...PostFields
          }
        }
      }
    }
  `,
    {
      variables: {
        id: isDraft ? postPreview.id : slug,
        idType: isDraft ? "DATABASE_ID" : "SLUG",
      },
    }
  );

  // Draft posts may not have an slug
  if (isDraft) data.post.slug = postPreview.id;
  // Apply a revision (changes in a published post)
  if (isRevision && data.post.revisions) {
    const revision = data.post.revisions.edges[0]?.node;

    if (revision) Object.assign(data.post, revision);
    delete data.post.revisions;
  }

  // Filter out the main post
  data.posts.edges = data.posts.edges.filter(({ node }) => node.slug !== slug);
  // If there are still 3 posts, remove the last one
  if (data.posts.edges.length > 2) data.posts.edges.pop();

  return data;
}

export async function getPageBySlug(slug) {
  const { pageBy } = await fetchAPI(
    `
    query PageBySlug($uri: String!) {
      pageBy(uri: $uri) {
        id,
        slug
        seo {
          opengraphImage {
            sourceUrl
          }
          title
          metaDesc
          opengraphUrl
          opengraphType
          opengraphTitle
          opengraphSiteName
          opengraphPublisher
          opengraphPublishedTime
          opengraphModifiedTime
          opengraphDescription
        }
        ${blocksFragments}
      }
    }
  `,
    {
      variables: {
        uri: slug,
      },
    }
  );
  return {
    page: pageBy,
  };
}

export async function getCustomOptions() {
  const { options } = await fetchAPI(
    `
      query GetCustomOptions {
        options {
          cta {
            title
            subtitle
            icon {
              node {
                sourceUrl
                mediaDetails {
                  height
                  width
                }
                altText
                title
              }
            }
            button {
              title
              url
            }
          }
          share {
            shareFacebook
            shareTwitter
            shareLinkedin
            shareEmail
          }
          footer {
            bottomRightLinks {
              link {
                url
                title
              }
            }
            button {
              url
              title
              target
            }
            copyrights
            twitter
            linkedin
            text
            topLeftHeading
            topRightHeading
            topRightLinks {
              link {
                url
                title
              }
            }
            topLeftLinks {
              link {
                title
                url
              }
            }
          }
        }
      }
    `,
    {
      variables: {},
    }
  );
  return options;
}

export async function getHomepage() {
  const data = await fetchAPI(
    `
      query GetCustomOptions {
        nodeByUri(uri: "/") {
          ... on Page {
            id
            slug
            title
            seo {
              opengraphImage {
                sourceUrl
              }
              title
              metaDesc
              opengraphUrl
              opengraphType
              opengraphTitle
              opengraphSiteName
              opengraphPublisher
              opengraphPublishedTime
              opengraphModifiedTime
              opengraphDescription
            }
            ${blocksFragments}
          }
        }
      }
    `,
    {
      variables: {},
    }
  );
  return data.nodeByUri;
}

export async function getResources(searchPhrase) {
  const data = await fetchAPI(
    `
      query GetResources {
        resources(first: 99999, ${
          searchPhrase ? `where: {search: "${searchPhrase}"}` : ""
        }){
          nodes {
            title
            uri
            template {
              templateName
            }
            excerpt
            categories {
              nodes {
                slug
                name
              }
            }
            commonFields {
              label
              authors {
                website
                name
                description
                avatar {
                  node {
                    sourceUrl
                    uri
                  }
                }
              }
            }
            podcastFields {
              participants {
                avatar {
                  node {
                    sourceUrl
                  }
                }
                website
                name
                description
              }
              podcasts {
                website
                name
                description
                avatar {
                  node {
                    sourceUrl
                  }
                }
              }
            }
            status
            slug
            resourceId
            content(format: RENDERED)
            date
            dateGmt
            author {
              node {
                username
                description
                avatar {
                  width
                  url
                  height
                }
                name
                nicename
                nickname
                firstName
                lastName
              }
            }
            featuredImage {
              node {
                uri
                slug
                sizes(size: LARGE)
                sourceUrl
                mediaDetails {
                  width
                  height
                }
                link
                id
                sourceUrl
                altText
                title
              }
            }
          }
        }
      }
    `,
    {
      variables: {},
    }
  );
  return data.resources;
}

export async function getResourceBySlug(slug) {
  const data = await fetchAPI(
    `
      query GetResource {
        resourceBy(
          slug: "${slug}"
        ) {
          content(format: RENDERED)
          title
          slug
          id
          seo {
            opengraphImage {
              sourceUrl
            }
            title
            metaDesc
            opengraphUrl
            opengraphType
            opengraphTitle
            opengraphSiteName
            opengraphPublisher
            opengraphPublishedTime
            opengraphModifiedTime
            opengraphDescription
          }
          categories {
            nodes {
              slug
              name
            }
          }
          featuredImage {
            node {
              uri
              sourceUrl
              mediaDetails {
                width
                height
              }
              altText
              title
            }
          }
          date
          dateGmt
          commonFields {
            label
            externalLink
            authors {
              website
              name
              description
              avatar {
                node {
                  sourceUrl
                }
              }
            }
          }
          reportFields {
            report {
              node {
                uri
                link
                sourceUrl
              }
            }
            partners {
              website
              name
              description
              avatar {
                node {
                  sourceUrl
                }
              }
            }
          }
          podcastFields {
            participants {
              avatar {
                node {
                  sourceUrl
                }
              }
              website
              name
              description
            }
            podcasts {
              website
              name
              description
              avatar {
                node {
                  sourceUrl
                }
              }
            }
          }
          pressFields {
            sources {
              website
              name
              description
               avatar {
                node {
                  sourceUrl
                }
              }
            }
          }
          speakingFields {
            fieldGroupName
            presenters {
              website
              name
              fieldGroupName
              description
              avatar {
                node {
                  sourceUrl
                }
              }
            }
            sponsors {
              website
              name
              fieldGroupName
              description
              avatar {
                node {
                  sourceUrl
                }
              }
            }
          }
          author {
            node {
              username
              description
              avatar {
                width
                url
                height
              }
              name
              nicename
              nickname
              firstName
              lastName
            }
          }
        }
      }
    `,
    {
      variables: {},
    }
  );
  return data.resourceBy;
}

export async function getTeam() {
  const data = await fetchAPI(
    `
      query GetTeam {
        employees(first: 99999) {
          nodes {
            title,
            featuredImage {
              node {
                sourceUrl
                mediaDetails {
                  width
                  height
                }
                altText
                title
              }
            }
            teamFields {
              background
              title
              role
              registrations {
                item
              }
              linkedin
              twitter
              leadership {
                item
              }
              experience {
                item
              }
              education {
                title
                description
              }
            }
          }
        }
      }
    `,
    {
      variables: {},
    }
  );
  return data.employees;
}

export async function getExperiences() {
  const data = await fetchAPI(
    `
      query GetExperiences {
        experiences(first: 99999) {
          nodes {
            title
            slug
            featuredImage {
              node {
                mediaDetails {
                  width
                  height
                }
                sourceUrl
                altText
                title
              }
            }
            content(format: RENDERED)
            tags {
              nodes {
                slug
                name
              }
            }
            areas {
              nodes {
                slug
                name
              }
            }
          }
        }
      }
    `,
    {
      variables: {},
    }
  );
  return data.experiences;
}
